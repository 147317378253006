import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Link, Route, Router, Routes } from "react-router-dom";
import ActivateAccount from "./services/activate-account";
import styled from "styled-components";
import EmailSender from "./services/email-sender";
import EmailSignatureGenerator from "./services/email-signature-generator";
import CreateAccount from "./services/create-account";
import DeleteOffers from "./services/delete-offers";
import PasswordReset from "./services/password-reset";

function Menu() {
  return (
    <div className="App">
      <h2>Konta biznesowe</h2>
      <StyledLink to="/create-account">Utwórz nowe konto biznesowe</StyledLink>
      <StyledLink to="/activate-account">Aktywuj konto biznesowe</StyledLink>
      <StyledLink to="/password-reset">Resetuj hasło do konta</StyledLink>
      <h2>Dodatkowe narzędzia</h2>
      <StyledLink to="/delete-offer">Usuń ogłoszenie</StyledLink>
      <StyledLink to="/email-sender">Wyślij szablon emaila</StyledLink>
      <StyledLink to="/email-signature-generator">Wygeneruj stopkę email</StyledLink>
    </div>
  );
}
function App() {
  return (
    <Wrapper className="App">
      <BrowserRouter>
        <h1>Usługi administracyjne | MotoRata</h1>
        <Menu/>
        <Routes>
          <Route exact path="/create-account" element={<CreateAccount />} />
          <Route exact path="/activate-account" element={<ActivateAccount />} />
          <Route exact path="/email-sender" element={<EmailSender />} />
          <Route exact path="/delete-offer" element={<DeleteOffers />} />
          <Route exact path="/password-reset" element={<PasswordReset />} />
          <Route exact path="/email-signature-generator" element={<EmailSignatureGenerator />} />
        </Routes>
      </BrowserRouter>
    </Wrapper>
  );
}

export default App;

const StyledLink = styled(Link)`
  display: block;
  text-align: left;
`;

const Wrapper = styled.div`
  padding: 3rem;
`;
