import { APIService } from "./APIService"

const registerProfile = (username, coordinatorId, profile) => APIService.post(`/admin/profile/register?username=${username}&coordinatorId=${coordinatorId}`, profile)

const activateProfile = (username, isOtomoto) => APIService.post(`/admin/profile/activate?username=${username}&isOtomoto=${isOtomoto}`)

const emailSender = (type, email, data) => APIService.post(`/admin/email-sender?type=${type}&email=${email}`, data)
const deleteOffer = (id) => APIService.delete(`/admin/offers/${id}`)
const resetPassword = (username, password) => APIService.post(`/admin/profile/reset-password?username=${username}&password=${password}`)

export const AdminService = {
  registerProfile,
  activateProfile,
  emailSender,
  deleteOffer,
  resetPassword,
}
