import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PublicService } from "../api/PublicService";
import { AdminService } from "../api/AdminService";

const provinces = [
  'Dolnośląskie',
  'Kujawsko-pomorskie',
  'Lubelskie',
  'Lubuskie',
  'Łódzkie',
  'Małopolskie',
  'Mazowieckie',
  'Opolskie',
  'Podkarpackie',
  'Podlaskie',
  'Pomorskie',
  'Śląskie',
  'Świętokrzyskie',
  'Warmińsko-mazurskie',
  'Wielkopolskie',
  'Zachodniopomorskie'
]

function CreateAccount() {
  const timeoutId = useRef()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()
  const [coordinators, setCoordinators] = useState([])
  const [accountType, setAccountType] = useState('partner')

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {isValid, errors},
  } = useForm({
    defaultValues: {
      email: "",
    },
  })

  const nip = watch("company.nip")

  const getCoordinators = useCallback(
    async () => {
      try {
        const coordinators = await PublicService.getCoordinators()
        setCoordinators(coordinators)
      } catch (e) {
        console.log("[ERROR getCoordinators API]", e)
      }
    },
    [],
  )

  const searchCompanyByNip = useCallback(
    async number => {
      if (!number) {
        return
      }

      try {
        const res = await PublicService.getCompanyFromGUS(number)
        setValue("company", {
          nip: number,
          name: res.name || "",
          address: res.address,
          city: res.city,
          province: res.province,
          postCode: res.postCode,
        })
        setValue("address", {
          address: res.address || "",
          city: res.city,
          province: res.province,
          postCode: res.postCode,
        })
      } catch (e) {
        console.log("[ERROR getCompanyFromGUS API]", e)
      }
    },
    [],
  )

  useEffect(() => {
    getCoordinators()

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      if (nip) {
        searchCompanyByNip(nip)
      }
    }, 1000)
  }, [nip])

  const onSubmit = useCallback(async state => {
    setLoading(true)
    try {
      const profile = await AdminService.registerProfile(state.email, state.coordinator, {
        name: state.name,
        company: state.company,
        address: state.address,
        email: state.profileEmail,
        phone: state.phone,
        type: accountType,
        params: {
          "otomotoUrl": state.otomotoUrl
        },
      })
      setResponse('success')
    } catch (e) {
      setResponse('error')
    } finally {
      setLoading(false)
    }
  }, [accountType])

  const onChangeAccountType = (e) => {
    setAccountType(e.target.value)
  }

  return (
    <Wrapper>
      <InputWrapper onSubmit={handleSubmit(onSubmit)}>
        <h2>Utwórz nowe konto biznesowe</h2>
        {
          response === 'error' &&
          <p style={{color: 'red'}}>Nie udało się utworzyć konta!</p>
        }
        {
          response === 'success' &&
          <p style={{color: 'green'}}>Konto zostało pomyślnie utworzone!</p>
        }
        <InputContent>
          <select name="type" onChange={onChangeAccountType}>
            <option value="partner">Partner - zapytania obsługiwane przez MotoRata</option>
            <option value="owner">Właściciel - zapytania obsługiwane przez użytkownika</option>
            <option value="hybrid">Hybryda</option>
          </select>
          <h3>Dane logowania</h3>
          <input {...register("email", {
            required: true
          })} placeholder="Adres email"/>
          <h3>Dane firmy</h3>
          <Grid>
            <input placeholder="NIP*" {...register("company.nip", {required: true})} />
            <input placeholder="Nazwa firmy*" {...register("company.name", {required: true})} />
          </Grid>
          <input placeholder="Adres*" {...register("company.address", {required: true})} />
          <Grid>
            <input placeholder="Kod pocztowy*" {...register("company.postCode", {required: true})} />
            <input placeholder="Miasto*" {...register("company.city", {required: true})} />
          </Grid>
          <select placeholder="Województwo*" {...register("company.province", {required: true})}>
            {
              provinces.map(el =>
                <option value={el}>{el}</option>
              )
            }
          </select>
          <h3>Główna lokalizacja siedziby</h3>
          <input placeholder="Adres*" {...register("address.address", {required: true})} />
          <Grid>
            <input placeholder="Kod pocztowy*" {...register("address.postCode", {required: true})} />
            <input placeholder="Miasto*" {...register("address.city", {required: true})} />
          </Grid>
          <select placeholder="Województwo*" {...register("address.province", {required: true})}>
            {
              provinces.map(el =>
                <option value={el}>{el}</option>
              )
            }
          </select>
          <h3>Dane profilu</h3>
          <p>Dane widoczne dla użytkowników na platformie</p>
          <input placeholder="Nazwa profilu*" {...register("name", {required: true})} />
          <Grid>
            <input placeholder="Adres email*" {...register("profileEmail", {required: true})} />
            <input placeholder="Numer telefonu*" {...register("phone", {required: true})} />
          </Grid>
          <div>
            <input placeholder="Adres otomoto" style={{width: "100%"}} {...register("otomotoUrl", {
              required: false,
              pattern: /^(https:\/\/www\.|https:\/\/).+([\-\.]otomoto.pl\/inventory)$/
            })} />
            <p>np: https://motorata.otomoto.pl/inventory</p>
          </div>
          <h3>Opiekun</h3>
          <select placeholder="Opiekun*" {...register("coordinator")}>
            <option value="">-</option>
            {
              coordinators.map(el =>
                <option value={el?.id}>{el?.name}</option>
              )
            }
          </select>
          <button disabled={!isValid} type="submit">
            Utwórz konto
          </button>
        </InputContent>
      </InputWrapper>
    </Wrapper>
  );
}

export default CreateAccount;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  > * {
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.form`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
`;
