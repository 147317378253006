import { APIService } from "./APIService"

const register = user => APIService.post(`/register-profile`, user)

const getCompanyFromGUS = nip => APIService.get(`/gus?nip=${nip}`)
const getCoordinators = () => APIService.get(`/coordinators`)

export const PublicService = {
  register,
  getCompanyFromGUS,
  getCoordinators,
}
