import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { AdminService } from "../api/AdminService";

function DeleteOffers() {
  const [id, setId] = useState()
  const [response, setResponse] = useState()

  const onSubmit = useCallback(async state => {
    try {
      await AdminService.deleteOffer(id)
      setResponse('success')
    } catch (e) {
      setResponse('error')
    }
  }, [id])

  return (
    <Wrapper>
      <InputWrapper>
        <h2>Usuń ogłoszenie z platformy</h2>
        {
          response === 'error' &&
          <p style={{color: 'red'}}>Nie udało się usunąć ogłoszenia!</p>
        }
        {
          response === 'success' &&
          <p style={{color: 'green'}}>Ogłoszenie zostało pomyślnie usunięte!</p>
        }
        <InputContent>
          <input placeholder="ID ogłoszenia" name="id" value={id} onChange={(e) => setId(e.target.value)}/>
          <button disabled={!id || id === ""} onClick={onSubmit}>
            Usuń ogłoszenie
          </button>
        </InputContent>
      </InputWrapper>
    </Wrapper>
  );
}

export default DeleteOffers;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  `;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  > * {
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
`;
