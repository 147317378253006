import styled from "styled-components";
import { useCallback, useState } from "react";
import { AdminService } from "../api/AdminService";

function PasswordReset() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [response, setResponse] = useState()

  const handleSend = useCallback(async () => {
    try {
      setResponse('loading')
      await AdminService.resetPassword(username, password)
      setResponse('success')
    } catch (e) {
      setResponse('error')
    }
  }, [username, password])

  return (
    <Wrapper>
      <InputWrapper>
        <h2>Resetuj hasło do konta</h2>
        {
          response === 'error' &&
          <p style={{color: 'red'}}>Nie udało się ustawić hasła!</p>
        }
        {
          response === 'success' &&
          <p style={{color: 'green'}}>Hasło zostało pomyślnie ustawione!</p>
        }
        <InputContent>
          <input placeholder="Adres email" name="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
          <input placeholder="Hasło" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          {
            response === 'loading' ?
              <p>Wysyłanie ...</p> :
              <button onClick={handleSend}>Resetuj hasło</button>
          }
        </InputContent>
      </InputWrapper>
    </Wrapper>
  );
}

export default PasswordReset;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  
  > * {
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
`;
