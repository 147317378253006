import styled from "styled-components";
import { useCallback, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { AdminService } from "../api/AdminService";

function ActivateAccount() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [email, setEmail] = useState(searchParams?.get("email"))
  const [isOtomoto, setIsOtomoto] = useState(true)
  const [response, setResponse] = useState()

  const handleSend = useCallback(async () => {
    try {
      setResponse('loading')
      await AdminService.activateProfile(email, isOtomoto)
      setResponse('success')
    } catch (e) {
      setResponse('error')
    }
  }, [email, isOtomoto])

  return (
    <Wrapper>
      <InputWrapper>
        <h2>Aktywuj konto biznesowe</h2>
        {
          response === 'error' &&
          <p style={{color: 'red'}}>Nie udało się aktywować konta!</p>
        }
        {
          response === 'success' &&
          <p style={{color: 'green'}}>Konto zostało pomyślnie aktywowane!</p>
        }
        <InputContent>
          <input placeholder="Adres email" name="name" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <label>
            <input
              type="checkbox"
              checked={isOtomoto}
              onChange={(e) => setIsOtomoto(e.target.checked)}
            />
            Przenieś do OTOMOTO
          </label>
          {
            response === 'loading' ?
              <p>Wysyłanie ...</p> :
              <button onClick={handleSend}>Aktywuj</button>
          }
        </InputContent>
      </InputWrapper>
    </Wrapper>
  );
}

export default ActivateAccount;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  
  > * {
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
`;
