import axios from "axios";
import qs from 'qs'
import { API_URL } from "../theme/constans";


export const APIService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
    }),
})

APIService.interceptors.request.use(
  async config => {
    config.baseURL = API_URL
    return config
  },
  error => {
    console.log(error.data)
    // eslint-disable-next-line no-undef
    Promise.reject(error)
  },
)

APIService.interceptors.response.use(
  async response => {
    return response.data
  },
  async error => {
    const err = error.toJSON()
    return Promise.reject(error)
  },
)
