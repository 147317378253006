import styled from "styled-components";
import { useCallback, useState } from "react";
import axios from "axios";
import { AdminService } from "../api/AdminService";

const coordinators = [
  {
    name: 'Renata Kmicic',
    slug: 'renata.kmicic',
    phone: '+48507772768',
  },
  {
    name: 'Julian Kujawiak',
    slug: 'julian.kujawiak',
    phone: '+48535233428',
  },
  {
    name: 'Adam Wolny',
    slug: 'adam.wolny',
    phone: '+48881441336',
  },
  {
    name: 'Mariusz Kołecki',
    slug: 'mariusz.kolecki',
    phone: '+48501310465',
  },
  {
    name: 'Marcin Młynarczyk',
    slug: 'marcin.mlynarczyk',
    phone: '+48602177517',
  },
  {
    name: 'Katarzyna Śliwa',
    slug: 'katarzyna.sliwa',
    phone: '+48507772161',
  },
  {
    name: 'Robert Grzymała',
    slug: 'robert.grzymala',
    phone: '+48533070515',
  },
  {
    name: 'Marcin Kluczewski',
    slug: 'marcin.kluczewski',
    phone: '+48735159313',
  }
]

function EmailSender() {
  const [template, setTemplate] = useState('welcome')
  const [email, setEmail] = useState()
  const [coordinator, setCoordinator] = useState("")
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState()
  const [response, setResponse] = useState()

  const handleSend = useCallback(async () => {
    try {
      setResponse('loading')
      let finalCoordinator = {};
      if(coordinator){
        finalCoordinator = JSON.parse(coordinator)
      }

      await AdminService.emailSender(template, email, {
        subject,
        message,
        fromName: finalCoordinator?.name,
        replyToName: finalCoordinator?.name,
        from: `${finalCoordinator?.slug}@motorata.pl`,
        replyTo: `${finalCoordinator?.slug}@motorata.pl`,
        coordinator_name: finalCoordinator?.name,
        coordinator_email: `${finalCoordinator?.slug}@motorata.pl`,
        coordinator_phone: finalCoordinator?.phone,
        coordinator_image: `https://pl-motorata-s3-public.s3.eu-central-1.amazonaws.com/email_signature/profiles/${finalCoordinator?.slug}.png`,
      })
      setResponse('success')
    } catch (e) {
      setResponse('error')
    }
  }, [email, template, coordinator, subject, message])

  const handleSetCoordinator = useCallback((value) => {
    setCoordinator(value)
    if (value && value != "") {
      window.localStorage.setItem('coordinator', value)
    }
  }, [])

  return (
    <Wrapper>
      <InputWrapper>
        <h2>Wyślij szablon emaila</h2>
        {
          response === 'error' &&
          <p style={{color: 'red'}}>Nie udało się wysłać emaila!</p>
        }
        {
          response === 'success' &&
          <p style={{color: 'green'}}>Email został wysłany pomyślnie!</p>
        }
        <InputContent>
          <select onChange={el => setTemplate(el.target.value)}>
            <option value="welcome">Email zapraszający do współpracy</option>
            <option value="accept_legal">Email do aktywacji konta utworzonego przez opiekuna</option>
            <option value="activate_otomoto">Email do aktywacji otomoto</option>
            <option value="re_activate_otomoto">Email do ponownej aktywacji otomoto</option>
          </select>
          {
            template === "welcome" &&
            <select value={coordinator} onChange={el => handleSetCoordinator(el.target.value)}>
              <option value="">Domyślny</option>
              {
                coordinators.map(el =>
                  <option value={JSON.stringify(el)}>{el.name}</option>
                )
              }
            </select>
          }
          <input placeholder="Adres email" name="name" value={email} onChange={(e) => setEmail(e.target.value)}/>
          {
            template === "welcome" &&
            <>
              <input placeholder="Tytuł" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)}/>
              <textarea placeholder="Wiadomość" name="message" value={message} onChange={(e) => setMessage(e.target.value)}/>
            </>
          }
          {
            response === 'loading' ?
              <p>Wysyłanie ...</p> :
              <button onClick={handleSend} disabled={template === "welcome" && coordinator === ""}>Wyślij</button>
          }
        </InputContent>
      </InputWrapper>
    </Wrapper>
  );
}

export default EmailSender;

const InputContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  > * {
    margin-right: 20px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
`;
